<template>
  <p class="clearfix mb-0">
    <copyright />
  </p>
</template>
<script>
import Copyright from '@/@core/components/copyright/Copyright.vue'

export default {
  components: {
    Copyright,
  },
}
</script>
